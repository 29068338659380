import React, { useState } from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import styled from "styled-components";
import {
  faMapMarkedAlt,
  faAt,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FontAwesome from "../utils/FontAwesome";

FontAwesome();

const Contact = () => {
  const [formState, setFormState] = useState({
    name: "",
    mail: "",
    phone: "",
    message: "",
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSudmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => alert("Success!"))
      .catch((error) => alert(error));

    e.preventDefault();
  };

  return (
    <Layout lien={2}>
      <SEO title="MVDev Développeur Web Freelance le Pradet, Création de site vitrine E-commerce, application | Contact" />
      <ContactWrapper>
        <Form>
          <ContactInfo>
            <h2>Entrer en contact</h2>
            <div className="contact-info">
              <div className="info">
                <FontAwesomeIcon icon={faMapMarkedAlt} className="icon" />
                <p>127 Avenue Jean Moulin 83220 le Pradet</p>
              </div>
              <div className="info">
                <FontAwesomeIcon icon={faAt} className="icon" />
                <p>contact@mvdev.fr</p>
              </div>
              <div className="info">
                <FontAwesomeIcon icon={faPhoneAlt} className="icon" />
                <p>06 49 73 92 93</p>
              </div>
            </div>
            <div className="socialWrapper">
              <h2>Pour nous suivre</h2>
              <ul>
                <li className="social-icon">
                  <a
                    href="https://www.facebook.com/MV-Dev-623769154934120"
                    target="_blank"
                    rel="noreferrer">
                    <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                  </a>
                </li>
                <li className="social-icon">
                  <a
                    href="https://www.instagram.com/m.v_dev/"
                    target="_blank"
                    rel="noreferrer">
                    <FontAwesomeIcon icon={["fab", "instagram"]} />
                  </a>
                </li>
                <li className="social-icon">
                  <a
                    href="https://twitter.com/MVDev6"
                    target="_blank"
                    rel="noreferrer">
                    <FontAwesomeIcon icon={["fab", "twitter"]} />
                  </a>
                </li>
              </ul>
            </div>
          </ContactInfo>
          <ContactForm>
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSudmit}
              action="/Success">
              <h2>Contacter moi !</h2>
              <input type="hidden" name="form-name" value="contact" />
              <InputWrapper>
                <label className="focus">Votre nom</label>
                <span className="focusSpan">Votre nom</span>
                <input
                  required
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={formState.name}
                />
              </InputWrapper>
              <InputWrapper>
                <label className="focus">Votre Email</label>
                <span className="focusSpan">Votre Email</span>
                <input
                  required
                  type="email"
                  name="mail"
                  onChange={handleChange}
                  value={formState.mail}
                />
              </InputWrapper>
              <InputWrapper>
                <label className="focus">Téléphone</label>
                <span className="focusSpan">Téléphone</span>
                <input
                  required
                  name="phone"
                  onChange={handleChange}
                  value={formState.phone}></input>
              </InputWrapper>
              <InputWrapper>
                <label className="text focus">Message</label>
                <span className="focusSpan">Message</span>
                <textarea
                  className="input "
                  required
                  name="message"
                  rows="5"
                  onChange={handleChange}
                  value={formState.message}></textarea>
              </InputWrapper>
              <InputWrapper>
                <input className="btn" type="submit" value="Envoyer" />
              </InputWrapper>
            </form>
          </ContactForm>
        </Form>
      </ContactWrapper>
    </Layout>
  );
};

const ContactWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background: #fafafa;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato", sans-serif;
`;
const Form = styled.div`
  width: 100%;
  max-width: 850px;
  background: #fff;
  min-height: 500px;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-family: "Lato", sans-serif;
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.7rem;
    font-family: "Roboto Slab", serif;
  }

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr;
  } ;
`;

const ContactInfo = styled.div`
  background: #fff;
  padding-top: 1.2rem;

  .contact-info {
    padding: 2.2rem 2.3rem;
    position: relative;
  }

  .info {
    display: flex;
    color: #333;
    margin: 0.7rem 0;
    align-items: center;
    font-size: 0.95rem;
  }

  .icon {
    font-size: 26px;
    color: #333;
    margin-top: -2rem;
    margin-right: 1rem;
  }

  .socialWrapper {
    padding: 2rem 0 0 0;
    justify-content: center;

    ul {
      display: flex;
      margin-top: 2rem;
      justify-content: center;
      align-items: center;
    }
  }
  .social-icon {
  }
  .social-icon a {
    font-size: 26px;
    color: #333;
    margin: 1rem;
  }
`;
const ContactForm = styled.div`
  background: #e2e8f0;
  position: relative;
  justify-content: center;
  padding-top: 1.2rem;

  :before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background: #e2e8f0;
    transform: rotate(45deg);
    top: 50px;
    left: -13px;

    form {
      padding: 2.3rem 2.2rem;
      z-index: 10;
      overflow: hidden;
      position: relative;
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;
  margin: 0 2rem;

  .focus label {
    top: 17px;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
  }

  .focus span::before,
  .focus span::after {
    width: 50%;
    opacity: 1;
  }

  label {
    position: absolute;
    top: 17px;
    left: 25px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #333;
    font-weight: 400;
    pointer-events: none;
    z-index: 1000;
    transition: 0.5s;
    font-size: 0.8rem;
  }

  input {
    width: 100%;
    outline: none;
    margin: 1rem 0;
    border: 2px solid #00bfff;
    background: none;
    padding: 0.6rem 1.2rem;
    font-weight: 500;
    color: #333;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 25px;
    transition: 0.3s;
  }
  textarea.input {
    width: 100%;
    margin: 1rem 0;
    border: 2px solid #00bfff;
    background: none;
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    border-radius: 22px;
    resize: none;
    overflow-y: auto;
    font-weight: 500;
    color: #333;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 25px;
    transition: 0.3s;
    outline: none;
  }
  .text {
    top: 0.3rem;
    transform: translateY(0);
  }
  .btn {
    width: 130px;
    padding: 0.6rem 1.3rem;
    background: #fff;
    border: 2px solid #00bfff;
    font-size: 0.95rem;
    color: #333;
    line-height: 1;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
  }

  .btn:hover {
    color: #fff;
    background: #00bfff;
  }

  span {
    position: absolute;
    top: 17px;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
  }

  span:before,
  span:after {
    content: "";
    position: absolute;
    width: 50%;
    opacity: 1;
    transition: 0.3s;
    height: 6px;
    background: #e2e8f0;
    top: 50%;
    transform: translateY(-50%);
  }

  span:before {
    left: 50%;
  }
  span:after {
    right: 50%;
  }
`;
export default Contact;
